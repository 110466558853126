import React, { useState, useEffect } from "react"
import "../../components/layout.css"
import Iframe from "react-iframe"
import Seo from "../../components/seo"
import axios from "axios"
import * as queryString from "query-string"
import PopupContainer from "../../components/popupContainer";
import Uploader from "../../components/uploader";

const FeedbackPage = ({ location }) => {
    const { name } = queryString.parse(location.search);
    const feedback = "Sí";
    const [loading, setLoading] = useState(true);
    const [session, setSession] = useState("no sesion");

    const popUpTheme = '#083E60';
    const [uploadType, setUploadType] = useState("");
    const [clientId, setClientId] = useState("");
    const [uploadContainerVisibility, setUploadContainerVisibility] = useState(false);

    const headers = {
        Authorization: "bearer 40b3ff5fdeaf4ca6851eecadd6eec23c",
    }

    useEffect(() => {
        getBotId();
        eventSubscribe();
    }, [])

    let closeUploadPopupHandler = () => {
        setUploadContainerVisibility( false );
        replyAsUser("Volver");
    }

    const getBotId = async () => {
        try {
            const getBotId = await axios({
                method: "get", //you can set what request you want to be
                url:
                    "https://api.33bot.io/v1/conversation/chat/632b107ff2599d000982e1b6/bots",
                headers,
            })
            //const bot_id = getBotId.data[0].id

            const newSession = await axios({
                method: "post", //you can set what request you want to be
                url: "https://api.33bot.io/v1/conversation/create",
                data: {
                    bot_id: getBotId.data[0].id,
                },
                headers,
            })

            const someSession = newSession.data.id
            setSession(someSession)

            const updateSession = await axios({
                method: "post", //you can set what request you want to be
                url: "https://api.33bot.io/v1/conversation/update",
                data: {
                    session_id: someSession,
                    global_vars: {
                        nombre: {
                            text: name,
                            value: name,
                        },
                        feedback: {
                            text: feedback,
                            value: feedback,
                        },
                    },
                },
                headers,
            })

            if (updateSession.data.status === "ok") {
                setLoading(false)
            }
        } catch (error) {
            console.log(error)
        }
        console.log(`iniciando chatbot con sesión ${session}`)
    }

    const eventSubscribe = () => {
        window.addEventListener(
            "message",
            function (event) {
                const data = event.data
                if (event.data.event) {
                    switch (data.event) {
                        case "clearStorage":
                            localStorage.removeItem("session")
                            break
                        case "select":
                            break;
                        case "upload":
                            setUploadType(data.variable)
                            setClientId(data.client_id)
                            setUploadContainerVisibility(true)
                            break;
                        default:
                            console.log(data)
                    }
                }
            },
            false
        )
    }

    let replyAsUser = async( message ) => {

        await axios({
            method: "post", //you can set what request you want to be
            url: "https://api.33bot.io/v1/conversation/message/user",
            data: {
                session_id: session,
                text: message,
            },
            headers,
        })
    }

    const updateUploadData = async (uploadedType, uploadedPath) => {
        let dataVars = {};
        dataVars[uploadedType] = {
            text: uploadedPath,
            value: uploadedPath,
        };

        await axios({
            method: "post", //you can set what request you want to be
            url: "https://api.33bot.io/v1/conversation/update",
            data: {
                session_id: session,
                global_vars: dataVars,
            },
            headers,
        })

        let responseMessage = 'El archivo se ha subido correctamente';

        await axios({
            method: "post", //you can set what request you want to be
            url: "https://api.33bot.io/v1/conversation/message/user",
            data: {
                session_id: session,
                text: responseMessage,
            },
            headers,
        })
    }

    let uploadPopupCloseHandler = (uploadedType, uploadedPath) => {
        updateUploadData(uploadedType, uploadedPath)
        setUploadContainerVisibility(false)
    }


    return (
        <>
            <Seo title="Trabaja en Modern Talent Hub" />
            <div style={{ width: "100vw", height: "100vh" }}>
                {loading ? (
                    <div>Cargando...</div>
                ) : (
                    <Iframe
                        url={`https://chat.33bot.io/632d7d7fdaebe70009201ad7?r=web&close=0&session=${session}`}
                        width="100%"
                        height="100%"
                        allow="camera;microphone"
                        frameborder="0"
                    />
                )}

                <PopupContainer
                    onClose={closeUploadPopupHandler}
                    show={uploadContainerVisibility}
                    title={"Sube tu archivo"}
                    showButton={false}
                >
                    <Uploader
                        uploadType={uploadType}
                        updateData={updateUploadData}
                        popupClose={uploadPopupCloseHandler}
                        popUpTheme={popUpTheme}
                        clientId={clientId}
                    />
                </PopupContainer>

            </div>
        </>
    )
}

export default FeedbackPage
